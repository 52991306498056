// Dashboard.jsx
import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { fetchCampaigns } from '../services/api';

const Dashboard = ({ userId, onLogout }) => {
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [totalImpressions, setTotalImpressions] = useState(0);
  const [totalClicks, setTotalClicks] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const data = await fetchCampaigns(userId);
        const campaigns = data.results || [];

        setTotalCampaigns(campaigns.length);

        const impressions = campaigns.reduce((sum, campaign) => sum + (campaign.impressions || 0), 0);
        const clicks = campaigns.reduce((sum, campaign) => sum + (campaign.clicks || 0), 0);

        setTotalImpressions(impressions);
        setTotalClicks(clicks);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [userId]);

  if (loading) return <p>Carregando dados...</p>;
  if (error) return <p>Erro ao carregar dados: {error}</p>;

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1>Bem-vindo ao MeliAd Manager</h1>
        <p>Gerencie suas campanhas e acompanhe suas métricas em tempo real.</p>
        <button onClick={onLogout} className="logout-button">
          Sair
        </button>
      </header>
      <main className="dashboard-main">
        <section className="dashboard-card">
          <h2>Total de Campanhas</h2>
          <p>{totalCampaigns}</p>
        </section>
        <section className="dashboard-card">
          <h2>Impressões Totais</h2>
          <p>{totalImpressions}</p>
        </section>
        <section className="dashboard-card">
          <h2>Cliques Totais</h2>
          <p>{totalClicks}</p>
        </section>
      </main>
    </div>
  );
};

export default Dashboard;

