// frontend api.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001',
});

export async function fetchCampaigns(userId) {
  try {
    const response = await axios.get(`${API_BASE_URL}/meli/campaigns`, {
      params: { userId },
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar campanhas:', error.response?.data || error.message);
    throw error;
  }
}

export default api;

