import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import AdminLogin from './pages/AdminLogin';
import Dashboard from './pages/Dashboard';
import AdminDashboard from './pages/AdminDashboard';
import ProtectedRoute from './components/ProtectedRoute';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Inicializa como null
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(null); // Inicializa como null

  // Carrega os estados de autenticação do localStorage ao carregar o app
  useEffect(() => {
    const userAuth = localStorage.getItem('isAuthenticated') === 'true';
    const adminAuth = localStorage.getItem('isAdminAuthenticated') === 'true';
    setIsAuthenticated(userAuth);
    setIsAdminAuthenticated(adminAuth);
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  const handleAdminLogin = () => {
    setIsAdminAuthenticated(true);
    localStorage.setItem('isAdminAuthenticated', 'true');
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
  };

  const handleAdminLogout = () => {
    setIsAdminAuthenticated(false);
    localStorage.removeItem('isAdminAuthenticated');
  };

  // Exibe "Carregando..." enquanto os estados são inicializados
  if (isAuthenticated === null || isAdminAuthenticated === null) {
    return <div>Carregando...</div>;
  }

  return (
    <Router>
      <Routes>
        {/* Login do usuário */}
        <Route path="/" element={<Login onLogin={handleLogin} />} />

        {/* Login do administrador */}
        <Route path="/admin" element={<AdminLogin onAdminLogin={handleAdminLogin} />} />

        {/* Dashboard do usuário */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Dashboard onLogout={handleLogout} />
            </ProtectedRoute>
          }
        />

        {/* Dashboard do administrador */}
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute isAuthenticated={isAdminAuthenticated}>
              <AdminDashboard onLogout={handleAdminLogout} />
            </ProtectedRoute>
          }
        />

      </Routes>
    </Router>
  );
};

export default App;
