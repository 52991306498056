import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css';

const AdminLogin = ({ onAdminLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    // Mock de credenciais do administrador
    const mockAdmin = { username: 'admin', password: 'admin123' };

    console.log('Tentativa de login do administrador:', username, password);

    if (!username || !password) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Por favor, preencha todos os campos!',
        confirmButtonColor: '#ff6f61',
      });
      return;
    }

    if (username === mockAdmin.username && password === mockAdmin.password) {
      Swal.fire({
        icon: 'success',
        title: 'Bem-vindo, Administrador!',
        text: 'Login realizado com sucesso!',
        confirmButtonColor: '#ff6f61',
      }).then(() => {
        onAdminLogin(); // Salva a autenticação no localStorage
        navigate('/admin-dashboard'); // Redireciona para o dashboard do admin
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Usuário ou senha inválidos!',
        confirmButtonColor: '#ff6f61',
      });
    }
  };

  return (
    <div className="admin-login-container">
      <div className="admin-login-left">
        <h1>MeliAd Manager</h1>
        <p>Área exclusiva para administradores.</p>
      </div>
      <div className="admin-login-right">
        <form className="admin-login-form" onSubmit={handleLogin}>
          <h2>Login Administrador</h2>
          <input
            type="text"
            placeholder="Usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Entrar</button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
