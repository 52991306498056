import React, { useState } from 'react';
import Swal from 'sweetalert2';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post('/auth/login', { username, password });
      const { user } = response.data;

      // Salvar os dados do usuário no localStorage
      localStorage.setItem('user', JSON.stringify(user));

      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Login realizado com sucesso!',
        confirmButtonColor: '#fee708',
      });

      onLogin(user); // Atualiza o estado global, se necessário
      navigate('/dashboard'); // Redireciona para o dashboard
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: error.response?.data?.message || 'Erro ao realizar login',
        confirmButtonColor: '#fee708',
      });
    }
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <h1>MeliAd Manager</h1>
        <p>Gerencie suas campanhas no Mercado Livre de forma fácil e eficiente.</p>
      </div>
      <div className="login-right">
        <form className="login-form" onSubmit={handleLogin}>
          <h2>Bem-vindo</h2>
          <input
            type="text"
            placeholder="Usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Entrar</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
