import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa'; // Ícones de ordenação
import api from '../services/api';
import './AdminDashboard.css';

const AdminDashboard = ({ onLogout }) => {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [ascending, setAscending] = useState(true); // Estado para controle da ordenação

  // Fetch users from the backend
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/users');
        setUsers(Array.isArray(response.data.users) ? response.data.users : []); // Use os dados corretamente
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
        Swal.fire({
          title: 'Erro!',
          text: 'Erro ao carregar os usuários.',
          icon: 'error',
          confirmButtonColor: '#e74c3c',
        });
      }
    };

    fetchUsers();
  }, []);

  const handleSaveUser = async (user) => {
    try {
        if (editingUser) {
            // Editar usuário existente
            const response = await api.put(`/users/${editingUser._id}`, user);
            setUsers(users.map((u) => (u._id === editingUser._id ? response.data : u))); // Atualiza o usuário na lista
            Swal.fire('Sucesso!', 'Usuário atualizado com sucesso.', 'success');
        } else {
            // Criar novo usuário
            const response = await api.post('/users', user);
            const updatedUsers = await api.get('/users'); // Atualiza a lista completa
            setUsers(Array.isArray(updatedUsers.data.users) ? updatedUsers.data.users : []);
            Swal.fire('Sucesso!', 'Usuário criado com sucesso.', 'success');
        }
        setIsModalOpen(false);
        setEditingUser(null);
    } catch (error) {
        console.error('Erro ao salvar usuário:', error);
        Swal.fire('Erro!', 'Erro ao salvar o usuário.', 'error');
    }
};


  const handleDeleteUser = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Tem certeza?',
        text: 'Essa ação não pode ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#e74c3c',
        cancelButtonColor: '#2980b9',
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
      });
      if (result.isConfirmed) {
        await api.delete(`/users/${id}`);
        const updatedUsers = await api.get('/users'); // Atualiza a lista completa
        setUsers(Array.isArray(updatedUsers.data.users) ? updatedUsers.data.users : []);
        Swal.fire('Excluído!', 'Usuário excluído com sucesso.', 'success');
      }
    } catch (error) {
      Swal.fire('Erro!', 'Erro ao excluir o usuário.', 'error');
    }
  };

  const toggleSort = () => {
    setAscending(!ascending); // Alterna entre A-Z e Z-A
    const sortedUsers = [...users].sort((a, b) => {
      if (ascending) {
        return a.name.localeCompare(b.name); // Ordenação A-Z
      } else {
        return b.name.localeCompare(a.name); // Ordenação Z-A
      }
    });
    setUsers(sortedUsers);
  };

  const filteredUsers = Array.isArray(users)
    ? users.filter(
        (user) =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.login.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.token.includes(searchTerm)
      )
    : [];

  return (
    <div className="admin-dashboard-container">
      <header className="admin-dashboard-header">
        <h1>Painel do Administrador</h1>
        <button className="logout-button" onClick={onLogout}>
          Sair
        </button>
      </header>
      <main className="admin-dashboard-main">
        <section className="admin-dashboard-section">
          <h2>Gerenciamento de Usuários</h2>
          <input
            type="text"
            placeholder="Buscar por nome, login ou token"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <button
            className="action-button"
            onClick={() => {
              setEditingUser(null);
              setIsModalOpen(true);
            }}
          >
            Criar Usuário
          </button>
          <h5>Total de Usuários: {users.length}</h5>
          <table className="users-table">
            <thead>
              <tr>
                <th>
                  Nome{' '}
                  <span onClick={toggleSort} style={{ cursor: 'pointer' }}>
                    {ascending ? <FaSortAlphaDown /> : <FaSortAlphaUp />}
                  </span>
                </th>
                <th>Login</th>
                <th>Token</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user._id}>
                  <td>{user.name}</td>
                  <td>{user.login}</td>
                  <td>{user.token}</td>
                  <td>
                  <button
                      className="edit-button"
                      onClick={() => {
                        setEditingUser(user);
                        setIsModalOpen(true);
                      }}
                    >
                      Editar
                    </button>
                    <button className="delete-button" onClick={() => handleDeleteUser(user._id)}>
                      Excluir
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
      {isModalOpen && (
        <UserModal
          user={editingUser}
          onSave={handleSaveUser}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

const UserModal = ({ user, onSave, onClose }) => {
  const [formData, setFormData] = useState(
    user || { name: '', login: '', password: '', token: '' }
  );
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <div className="user-modal">
      <div className="modal-content">
        <h2>{user ? 'Editar Usuário' : 'Criar Usuário'}</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Nome"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="login"
            placeholder="Login"
            value={formData.login}
            onChange={handleChange}
            required
          />
          <div className="password-container">
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Senha"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </span>
          </div>
          <input
            type="text"
            name="token"
            placeholder="Token"
            value={formData.token}
            onChange={handleChange}
            required
          />
          <div className="modal-actions">
            <button type="submit">Salvar</button>
            <button type="button" onClick={onClose}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminDashboard;
